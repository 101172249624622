import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import emailjs from '@emailjs/browser';

// Simple CSS Spinner
const Spinner = () => (
  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
);

const CustomForm = ({ apiUrl }: any) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    stateAndInstructions: '',
    selectedService: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const variants = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 0.6 } },
    hidden: { opacity: 0, translateY: 20 },
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const sendEmail = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    const templateParams = {
      name: formData.firstName.concat(' ', formData.lastName),
      email: formData.email,
      phone: formData.phone,
      inquiry: formData.stateAndInstructions,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID!,
        process.env.REACT_APP_TEMPLATE_ID!,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY!
      );
      setIsSubmitted(true);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        stateAndInstructions: '',
        selectedService: '',
      });
    } catch (error) {
      console.error('Failed to send email:', error);
    }
    setIsSubmitting(false);
  };

  const debouncedSendEmail = debounce(sendEmail, 1000);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className="my-10 p-6 pb-10 bg-secondary rounded text-xl md:w-[60%] xl:w-[40%]"
    >
      <form
        onSubmit={(e: any) => {
          e.persist(); // This line allows the event to be used in an async function
          e.preventDefault(); // Prevent the default form submission behavior
          debouncedSendEmail(e);
        }}
      >
        <h2 className=" font-bold mb-4">Start Your Service Here!</h2>
        {/* Form inputs remain unchanged */}
        <div className="mb-4">
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-black"
          >
            First name *
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-black"
          >
            Last name *
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-black"
          >
            Email *
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="stateAndInstructions"
            className="block text-sm font-medium text-black"
          >
            Message *
          </label>
          <textarea
            id="stateAndInstructions"
            name="stateAndInstructions"
            value={formData.stateAndInstructions}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none"
            required
          ></textarea>
        </div>
        {/* phone */}
        <div className="mb-4">
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-black"
          >
            Phone
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none"
          />
        </div>
        {/* <div className="mb-4">
          <label
            htmlFor="selectedService"
            className="block text-sm font-medium text-black"
          >
            House Size *
          </label>
          <select
            id="selectedService"
            name="selectedService"
            value={formData.selectedService}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none"
            required
          >
            <option value="">Select Size</option>
            <option value="Small House">Small House</option>
            <option value="Medium House">Medium House</option>
            <option value="Large House">Large House</option>
            <option value="Mansion">Mansion</option>
            <option value="Commercial Property">Commercial Property</option>
          </select>
        </div> */}
        <button
          type="submit"
          className={`w-full ${
            isSubmitted
              ? 'bg-secondary text-primary'
              : 'bg-primary text-secondary'
          } hover:bg-green-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline relative`}
          disabled={isSubmitting || isSubmitted}
        >
          {isSubmitting ? (
            <div className="w-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : isSubmitted ? (
            'Submitted'
          ) : (
            'Send Message'
          )}
        </button>
      </form>
    </motion.div>
  );
};

export default CustomForm;
