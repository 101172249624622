import React from 'react';

interface Props {
  col?: boolean;
}
export default function MenuItems(props: Props) {
  return (
    <ul
      className={`flex ${
        props.col && 'flex-col'
      } items-start justify-center gap-6 text-2xl font-semibold bg-secondary p-12 md:px-10 md:py-9 rounded-3xl`}
    >
      <li>
        <a className="transition `" href="/">
          Home
        </a>
      </li>

      {/* <li>
        <a className="transition hover:text-gray-500/75" href="/privacy">
          Privacy Policy
        </a>
      </li> */}

      <li className="bg-primary rounded-lg px-2 py-1 glow-effect">
        <a
          className="transition hover:text-gray-500/75 text-secondary"
          href="/contact"
        >
          Get a Free Quote Now!
        </a>
      </li>

      <li>
        <a
          href="tel:+18622623170"
          className="border-[1px] border-primary px-4 py-2 rounded-lg"
        >
          (862) 262-3170
        </a>
      </li>

      {/* contact */}
      {/* <li>
        <a className="transition hover:text-gray-500/75" href="/contact">
          Contact Us
        </a>
      </li> */}

      {/* <li>
        <a className="transition hover:text-gray-500/75" href="/history">
          History
        </a>
      </li> */}

      {/* <li>
        <a className="transition hover:text-gray-500/75" href="/services">
          Services
        </a>
      </li> */}
      {/* 
      <li>
        <a
          className="transition hover:text-gray-500/75"
          href="https://www.instagram.com/abated_acm/"
        >
          Instagram
        </a>
      </li> */}
      {/* <li>
        <a
          className="transition hover:text-gray-500/75"
          href="/privacypolicy"
        >
          Privacy Policy
        </a>
      </li>
      <li>
        <a
          className="transition hover:text-gray-500/75"
          href="/termsandconditions"
        >
          Terms & Conditions
        </a>
      </li> */}

      {/* <li>
        <a className="transition hover:text-gray-500/75" href="/blog">
          Blog
        </a>
      </li> */}
    </ul>
  );
}
